import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";
import StatusLogIndicator from "../../Components/StatusLogIndicator";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";

import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import DialogContentText from "@mui/material/DialogContentText";
import PaymentActions from "../../Components/PaymentActions";

/**
 * PaymentsTab component.
 *
 * @returns {*}
 * @constructor
 */
function PaymentsTab(props) {
    const {record} = props;

    const getDefaultMessage = () => `Order ID: #${record.order_number || ''}\nInspection Date: ${Formatter.dateTime(record.order_datetime || '')}\nAddress: ${record.order_full_address || ''}`;
    const getStreetAddress = () => {
        const address = record.order_full_address.split(', ', 1);

        return address[0];
    };

    const [records, setRecords] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [statusError, setStatusError] = useState(false);
    const [emailAddress, setEmailAddress] = useState(record.client_email || '');
    const [companyName, setCompanyName] = useState(record.companykey || '');
    const [contactName, setContactName] = useState(record.client_name || '');
    const [streetAddress, setStreetAddress] = useState(getStreetAddress());
    const [emailMessage, setEmailMessage] = useState(getDefaultMessage());
    const [isRequestOpen, setRequestOpen] = useState(false);
    const [requestAmount, setRequestAmount] = useState(record.amount || 0.00);
    const [useProcessingFee, setUseProcessingFee] = useState(true);
    const [customAmount, setCustomAmount] = useState(false);

    /**
     * Queries for all payment request records.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);
        const response = await API.send(`pay-at-close/transaction/${record.id}/payments`);

        if (response.payments.length > 0) {
            for (let i = 0; i < response.payments.length; i++) {
                // this fails when an amount is over one thousand dollars because of the comma
                // so this will remove the comma so the currency formatter works correctly
                response.payments[i].amount = String(response.payments[i].amount).replace(',', '');
            }
        }

        setRecords(response.payments);
        setCompanyName(response.companyName);
        setLoading(false);
    };


    /**
     * Closes the confirmation dialogue.
     */
    const handleRequestClose = () => {
        setRequestOpen(false);
    };


    /**
     * Opens the confirmation dialogue.
     */
    const handleRequestOpen = () => {
        setRequestOpen(true);
    };

    useEffect(() => {
        let amount = (customAmount === false ? record.amount || 0.00 : customAmount);
        if (useProcessingFee) {
            amount *= 1.03;
        }

        // make sure to round to two decimal places
        setRequestAmount(+(Math.round(amount + "e+2") + "e-2"));
    }, [useProcessingFee]);


    /**
     * Handles the resolve action against the target record.
     *
     * @returns {Promise<void>}
     */
    const doSend = async () => {
        setRecords([]);
        setLoading(true);

        await API.send(`pay-at-close/transaction/${record.id}/send-request`, 'POST', {
            emailAddress,
            requestAmount,
            emailMessage,
            companyName,
            contactName,
            streetAddress,
            useProcessingFee,
        }).then((response) => {
            if (response.status !== 'error') {
                setRequestOpen(false);
            }

            setStatusError(response.status === 'error');
        });

        await getResults();
    };

    const setNewRequestAmount = (amount) => {
        setCustomAmount(amount);
        setRequestAmount(amount);
        setUseProcessingFee(false);
    };

    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        getResults().then(() => true);
    }, [record]);


    return (
        <Paper className={'table__block'}>
            <div className={'block__heading block__heading--bordered'}>
                <div className={'columns__1'}>
                    <div className={'d-flex__justify'}>
                        <div>
                            <h3 className={'text__bold'}>Payments</h3>
                            <p className={'text__muted'}>
                                The following payments are available for the transaction.
                            </p>
                        </div>
                        <div>
                            <Button
                                variant={'contained'}
                                onClick={handleRequestOpen}
                                disabled={isLoading}
                            >Send Request</Button>
                        </div>
                    </div>
                </div>
            </div>
            <TableContainer component={'div'} className={'list__wrapper'}>
                <Table>
                    <colgroup>
                        <col width="18%"/>
                        <col width="18%"/>
                        <col width="18%"/>
                        <col width="18%"/>
                        <col width="18%"/>
                        <col/>
                    </colgroup>
                    <TableHead className={'table__header--small'}>
                        <TableRow>
                            <TableCell><b>Recipient</b></TableCell>
                            <TableCell><b>Amount</b></TableCell>
                            <TableCell><b>Status</b></TableCell>
                            <TableCell><b>Date/Time</b></TableCell>
                            <TableCell><b>Processing Fee Charged</b></TableCell>
                            <TableCell align={'right'}><b>Actions</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading && (
                            <TableRow>
                                <TableCell>
                                    <Skeleton width={'40%'}/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton width={'40%'}/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton width={'40%'}/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton width={'40%'}/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton width={'40%'}/>
                                </TableCell>
                                <TableCell align={'right'}>
                                    <Skeleton width={'70%'} style={{marginLeft: 'auto'}}/>
                                </TableCell>
                            </TableRow>
                        )}

                        {!isLoading && !records.length && (
                            <TableRow>
                                <TableCell colSpan={5} align={'center'}>
                                    <div className={'text__muted'}>
                                        No records available.
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}

                        {records.map((payment) => (
                            <TableRow key={payment.id}>
                                <TableCell>
                                    {Formatter.email(payment.email_address)}
                                </TableCell>
                                <TableCell>
                                    {Formatter.currency(payment.amount)}
                                </TableCell>
                                <TableCell>
                                    {payment.status}
                                </TableCell>
                                <TableCell>
                                    {Formatter.dateTime(payment.sent_date)}
                                </TableCell>
                                <TableCell>
                                    {payment.processing_fee ? 'Yes' : 'No'}
                                </TableCell>
                                <TableCell align={'right'}>
                                    <PaymentActions record={payment} onReload={getResults}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={isRequestOpen}
                onClose={handleRequestClose}
            >
                <DialogTitle>
                    Send Payment Request
                </DialogTitle>
                <DialogContent>
                    <div className={'columns__1'}>
                        <div className={`${statusError ? 'text__danger text__bold' : ''}`}>
                            Please provide an amount, email recipient, contact name, company name and street address to send this payment request to.
                        </div>

                        <TextField
                            autoFocus
                            value={emailAddress}
                            label="Email Address"
                            variant="outlined"
                            disabled={isLoading}
                            onChange={(event) => setEmailAddress(event.target.value)}
                        />

                        <TextField
                            autoFocus
                            value={contactName}
                            label="Contact Name"
                            variant="outlined"
                            disabled={isLoading}
                            onChange={(event) => setContactName(event.target.value)}
                        />

                        <TextField
                            autoFocus
                            value={companyName}
                            label="Company Name"
                            variant="outlined"
                            disabled={isLoading}
                            onChange={(event) => setCompanyName(event.target.value)}
                        />

                        <TextField
                            autoFocus
                            value={streetAddress}
                            label="Street Address"
                            variant="outlined"
                            disabled={isLoading}
                            onChange={(event) => setStreetAddress(event.target.value)}
                        />

                        <FormControl>
                            <TextField
                                autoFocus
                                value={requestAmount}
                                label={useProcessingFee ? "Amount (incl. 3% processing fee)" : "Amount"}
                                variant="outlined"
                                disabled={isLoading}
                                onChange={(event) => setNewRequestAmount(event.target.value)}
                            />
                        </FormControl>

                        <TextField
                            autoFocus
                            multiline
                            rows={4}
                            value={emailMessage}
                            label="Additional Details"
                            variant="outlined"
                            disabled={isLoading}
                            onChange={(event) => setEmailMessage(event.target.value)}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    Use Processing Fee (3%)? <Checkbox
                        checked={useProcessingFee}
                        onChange={(event) => setUseProcessingFee(!useProcessingFee)}
                    />
                    <Button onClick={doSend} autoFocus disabled={isLoading}>
                        Send
                    </Button>
                    <Button onClick={handleRequestClose} disabled={isLoading} color={'error'}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}

export default PaymentsTab;
import React, {Fragment, useEffect, useState} from "react";

import API from "../Generic/API";
import Formatter from "../Style/Formatter";

import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {useParams, useNavigate} from "react-router-dom";

/**
 * Payment component.
 *
 * @returns {*}
 * @constructor
 */
function Payment() {
    const {id} = useParams();
    const navigate = useNavigate();

    // Configure our page storage.
    const [error, setError] = useState('');
    const [payment, setPayment] = useState({});
    const [isLoading, setLoading] = useState(false);

    // Various form fields.
    const [cardNumber, setCardNumber] = useState('');
    const [billingZip, setBillingZip] = useState('');
    const [cardSecurity, setCardSecurity] = useState('');
    const [cardExpiration, setCardExpiration] = useState('');

    const currentYear = new Date().getFullYear();

    /**
     * Retrieves the payment record.
     *
     * @returns {Promise<void>}
     */
    const getPayment = async () => {
        setLoading(true);
        const response = await API.send(`payment/${id}`);
        // this fails when an amount is over one thousand dollars because of the comma
        // so this will remove the comma so the currency formatter works correctly
        response.amount = String(response.amount).replace(',', '');

        // Handle missing records.
        if (!response.id) {
            return navigate('/not-found');
        }

        setLoading(false);
        setPayment(response);
    };


    /**
     * If the user is already authenticated, redirect to the dashboard.
     */
    useEffect(() => {
        getPayment();
    }, []);


    /**
     * Redirect on user authentication.
     */
    const handlePayment = async () => {
        setError('');
        setLoading(true);

        const response = await API.send(`payment/${id}/charge`, 'POST', {
            cardNumber: getFormattedCardNumber(),
            cardExpiration: getFormattedExpiration(),
            cardSecurity,
            billingZip,
        });

        if(response.status !== 'success'){
            setError(response.message);
            setLoading(false);
            return;
        }

        await getPayment();
    };


    /**
     * Returns a formatted credit card number.
     *
     * @returns {string}
     */
    const getFormattedCardNumber = () => {
        const number = cardNumber.substring(0, 19);
        const raw = [...number.split(' ').join('')];
        const segments = [];

        raw.forEach((t, i) => {
            if (i % 4 === 0 && i !== 0) segments.push(' ');
            segments.push(t)
        });

        return segments.join('')
    };


    /**
     * Returns a formatted credit card expiration number.
     *
     * @returns {string}
     */
    const getFormattedExpiration = () => {
        const expiration = cardExpiration.substring(0, 5);
        const raw = [...expiration.split(' ').join('').split('/').join('')];
        const segments = [];

        raw.forEach((t, i) => {
            if (i % 2 === 0 && i !== 0) segments.push('/');
            segments.push(t)
        });

        return segments.join('')
    };

    return payment.id && (
        <div className={'login__wrapper'}>
            <Paper className={'login__container'}>
                <div className={'columns__1'}>
                    {payment.status === 'Unpaid' &&
                        <Fragment>
                            <div>
                                <img
                                    src={'/logo.png'}
                                    alt="Guardian Inspection Payments"
                                    className={'login__logo'}
                                />
                            </div>

                            {error && <Alert severity="error" children={<div>{error}</div>}/>}

                            <div>
                                Please enter your payment information as accurately as possible before attempting to process your balance of <b>{Formatter.currency(payment.amount)}</b>.
                            </div>

                            <TextField
                                autoFocus
                                label="Credit Card #"
                                variant="outlined"
                                placeholder={'**** **** **** ****'}
                                value={getFormattedCardNumber()}
                                onChange={(event) => setCardNumber(event.target.value)}
                                disabled={isLoading}
                            />

                            <TextField
                                autoFocus
                                label="Expiration Date"
                                placeholder={'MM/YY'}
                                variant="outlined"
                                value={getFormattedExpiration()}
                                onChange={(event) => setCardExpiration(event.target.value)}
                                disabled={isLoading}
                            />

                            <div className={'columns__2'}>
                                <TextField
                                    autoFocus
                                    label="CVV"
                                    variant="outlined"
                                    onChange={(event) => setCardSecurity(event.target.value)}
                                    disabled={isLoading}
                                />

                                <TextField
                                    autoFocus
                                    label="Postal Code"
                                    variant="outlined"
                                    onChange={(event) => setBillingZip(event.target.value)}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className={'columns__1'}>
                                <Button
                                    variant="contained"
                                    onClick={handlePayment}
                                    disabled={isLoading}
                                >Process</Button>
                            </div>
                        </Fragment>
                    }

                    {payment.status === 'Undeliverable' &&
                        <Fragment>
                            <div>
                                We were unable to successfully deliver this payment request.
                            </div>
                        </Fragment>
                    }

                    {payment.status === 'Paid' &&
                        <Fragment>
                            <Alert severity="success" children={<div>Successfully processed payment!</div>}/>

                            <div>
                                Your payment has been processed. Please keep this payment receipt for your records.
                            </div>

                            <div className={'d-flex__justify'}>
                                <b>Payment Date:</b>
                                <div>{Formatter.dateTime(payment.paid_date)}</div>
                            </div>

                            <div className={'d-flex__justify'}>
                                <b>Card Number:</b>
                                <div>****-****-****-{payment.last_four || '****'}</div>
                            </div>

                            <div className={'d-flex__justify'}>
                                <b>Amount:</b>
                                <div>{Formatter.currency(payment.amount)}</div>
                            </div>
                        </Fragment>
                    }

                    <div className={'login__footer'}>
                        © {currentYear} Guardian Financial — All Rights Reserved
                    </div>
                </div>
            </Paper>
        </div>
    );
}

export default Payment;

import React, {useEffect, useState, Fragment} from "react";

import API from "../Generic/API";
import Formatter from "../Style/Formatter";
import StatusChip from "../Components/StatusChip";
import NotesBlock from "./Transaction/NotesBlock";
import PaymentsTab from "./Transaction/PaymentsTab";
import StatusLogTab from "./Transaction/StatusLogTab";
import TabContainer from "../Components/TabContainer";
import FundingBlock from "./Transaction/FundingBlock";
import AgreementBlock from "./Transaction/AgreementBlock";
import IntegrationTab from "./Transaction/IntegrationTab";
import TitleCompanyBlock from "./Transaction/TitleCompanyBlock";
import TransactionActions from "../Components/TransactionActions";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {useParams, useNavigate, Link} from "react-router-dom";

/**
 * Transaction component.
 *
 * @returns {*}
 * @constructor
 */
function Transaction() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [tab, setTab] = useState('overview');
    const [record, setRecord] = useState({});
    const [notes, setNotes] = useState([]);
    const [account, setAccount] = useState({});
    const [processStatuses, setProcessStatuses] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);

    /**
     * Loads the transaction via the API.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);
        const response = await API.send(`pay-at-close/transaction/${id}`);

        // Handle missing records.
        if (!response.transaction.id) {
            return navigate('/not-found');
        }

        setRecord(response.transaction);
        setNotes(response.notes);
        setAccount(response.account);
        setProcessStatuses(response.processStatuses);

        setLoading(false);
    };


    /**
     * Scrolls to the top of the page so you can see the status of the saving
     */
    const scrollToTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };


    /**
     * Saves an arbitrary parameter key.
     *
     * @returns {Promise<void>}
     */
    const doPropertySave = async (key, value) => {

        setSaving(true);
        scrollToTop();
        setRecord({
            ...record,
            [key]: value
        });

        const response = await API.send(
            `pay-at-close/transaction/${record.id}/parameter`,
            'POST',
            {
                key, value
            }
        );

        setSaving(false);

        if (response.status === 'success') {
            getResults().then(() => true);
        }
    };


    /**
     * Saves the process status.
     *
     * @returns {Promise<void>}
     */
    const doProcessSave = async (value) => {

        setSaving(true);
        scrollToTop();
        setRecord({
            ...record,
            funding_status: value
        });

        let response = await API.send(
            `pay-at-close/transaction/${record.id}/process-status`,
            'POST',
            {
                status: value
            }
        )

        if(value === 'Guardian Funded')
        {
            var status = 'FUNDED';
            response = await API.send(
                `pay-at-close/transaction/${record.id}/status`,
                'POST',
                {
                    status
                });
        }

        setSaving(false);

        if (response.status === 'success') {
            getResults().then(() => true);
        }

    };

    /**
     * Saves the collections status.
     *
     * @returns {Promise<void>}
     */
    const doCollectionsSave = async (value) => {
        setSaving(true);
        scrollToTop();
        setRecord({
            ...record,
            collections_status: value
        });

        const response = await API.send(
            `pay-at-close/transaction/${record.id}/collections-status`,
            'POST',
            {
                status: value
            }
        );

        setSaving(false);

        if (response.status === 'success') {
            getResults().then(() => true);
        }
    };


    /**
     * Updates the selected tab.
     *
     * @param event
     * @param updated
     */
    const handleTabChange = (event, updated) => {
        setTab(updated);
    };


    /**
     * Loads the target transaction on initial mount.
     */
    useEffect(() => {
        getResults().then(() => true);
    }, []);

    return (
        <div className={'columns__1'}>
            <div className={'d-flex__justify tabbed__heading'}>
                <h3 className={'hide__mobile'}>Pay at Close</h3>

                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label="Overview" value="overview"/>
                    <Tab label="Inspection" value="inspection"/>
                    <Tab label="Status Log" value="status"/>
                    <Tab label="Payments" value="payments"/>
                    <Tab label="Funding" value="funding"/>
                </Tabs>
            </div>

            {!isLoading && !record.valid && (
                <Alert severity="error">This transaction is no longer valid, updates may not be acknowledged.</Alert>
            )}

            {!isLoading && record.valid && account.funding_status !== 'VERIFIED' && (
                <Alert severity="warning">This <Link to={`/account/${record.companykey}`}>account</Link> has not yet been verified for funding.</Alert>
            )}

            {!isLoading && isSaving && (
                <Alert severity="info">Your changes are being saved.</Alert>
            )}

            {!isLoading && !isSaving && (
                <Alert severity="success">Your changes are up to date.</Alert>
            )}

            <TabContainer value={tab} target={'overview'}>
                <div className={'columns__2_3'}>
                    <div className={'columns__1'}>
                        <Paper>
                            <div className={'columns__1'}>
                                <div className={'d-flex__justify'}>
                                    <div>
                                        <h3 className={'text__bold'}>Transaction Details</h3>
                                        <p className={'text__muted'}>
                                            The following details are available for the selected transaction.
                                        </p>
                                    </div>

                                    {record.id && (
                                        <div className={'transaction__actions'}>
                                            <IconButton href={`/transaction/${id}/charge`}>
                                                <AttachMoneyIcon/>
                                            </IconButton>
                                            <div className={'d__inline-block'}>
                                                <TransactionActions record={record} onReload={getResults}/>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <Divider/>

                                {isLoading ? (
                                    <Fragment>
                                        <div className={'columns__3'}>
                                            {Array.from(Array(7).keys()).map((i, j) => {
                                                return (
                                                    <div className={'detail__field'} key={j}>
                                                        <Skeleton
                                                            width={`${30 + Math.floor(Math.random() * 20)}%`}
                                                            height={'1.3em'}
                                                        />
                                                        <Skeleton
                                                            width={`${60 + Math.floor(Math.random() * 30)}%`}
                                                            height={'1.3em'}
                                                            style={{marginTop: '0.5em', marginBottom: '1em'}}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <Divider/>
                                        <div className={'columns__3'}>
                                            {Array.from(Array(9).keys()).map((i, j) => {
                                                return (
                                                    <div className={'detail__field'} key={j}>
                                                        <Skeleton
                                                            width={`${30 + Math.floor(Math.random() * 20)}%`}
                                                            height={'1.3em'}
                                                        />
                                                        <Skeleton
                                                            width={`${60 + Math.floor(Math.random() * 30)}%`}
                                                            height={'1.3em'}
                                                            style={{marginTop: '0.5em', marginBottom: '1em'}}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <Divider/>
                                        <div className={'columns__3'}>
                                            {Array.from(Array(2).keys()).map((i, j) => {
                                                return (
                                                    <div className={'detail__field'} key={j}>
                                                        <Skeleton
                                                            width={`${30 + Math.floor(Math.random() * 20)}%`}
                                                            height={'1.3em'}
                                                        />
                                                        <Skeleton
                                                            width={`${60 + Math.floor(Math.random() * 30)}%`}
                                                            height={'1.3em'}
                                                            style={{marginTop: '0.5em', marginBottom: '1em'}}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <div className={'columns__3'}>
                                            <div className={'detail__field'}>
                                                <b>Company Key</b>
                                                <div>
                                                    <Link to={`/account/${record.companykey}`}>{record.companykey}</Link>
                                                </div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Order ID</b>
                                                <div>
                                                    OID: #{record.order_number}
                                                </div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Status</b>
                                                <div>
                                                    <StatusChip record={record}/>
                                                </div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Order Amount</b>
                                                <div className={'amount__due'}>{Formatter.currency(record.amount)}</div>
                                            </div>


                                            <div className={'detail__field'}>
                                                <b>Inspector Amount</b>
                                                <div>{Formatter.currency(record.amount - (record.inspector_fee !== '' ? record.inspector_fee : 50))}</div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Order Date / Time</b>
                                                <div>{Formatter.date(record.order_datetime, 'MM/DD/YYYY hh:mm A')}</div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Charge Date</b>
                                                <div>{Formatter.date(record.cc_charge_dte)}</div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Inspector Email</b>
                                                <div>{Formatter.email(record.inspector_email)}</div>
                                            </div>
                                        </div>
                                        <Divider/>
                                        <div className={'columns__3'}>
                                            <div className={'detail__field'}>
                                                <b>Order Address</b>
                                                <div>{record.order_full_address}</div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Client Name</b>
                                                <div>{record.client_name}</div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Client Email</b>
                                                <div>{Formatter.email(record.client_email)}</div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Client Phone</b>
                                                <div>{Formatter.phone(record.client_phone)}</div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Client Address</b>
                                                <div>{record.client_full_address}</div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Account Number</b>
                                                <div>{record.account_number}</div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Agent Name</b>
                                                <div>{record.agent_name}</div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Agent Phone</b>
                                                <div>{Formatter.phone(record.agent_phone)}</div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Agent Email</b>
                                                <div>{Formatter.email(record.agent_email)}</div>
                                            </div>
                                        </div>
                                        <Divider/>
                                        <div className={'columns__3'}>
                                            <div className={'detail__field'}>
                                                <b>Title Confirmed Invoice Received</b>
                                                <div>
                                                    <ToggleButtonGroup
                                                        exclusive
                                                        value={!!record.title_confirmed}
                                                        onChange={(event, value) => doPropertySave('title_confirmed', value)}
                                                    >
                                                        <ToggleButton
                                                            size={'small'}
                                                            value={false}
                                                            children={'No'}
                                                        />
                                                        <ToggleButton
                                                            size={'small'}
                                                            value={true}
                                                            children={'Yes'}
                                                        />
                                                    </ToggleButtonGroup>
                                                </div>
                                            </div>

                                            <div className={'detail__field'}>
                                                <b>Queued for Funding</b>
                                                <div>
                                                    <ToggleButtonGroup
                                                        exclusive
                                                        value={!!record.inspector_needs_paid}
                                                        onChange={(event, value) => doPropertySave('inspector_needs_paid', value)}
                                                    >
                                                        <ToggleButton
                                                            size={'small'}
                                                            value={false}
                                                            children={'No'}
                                                        />
                                                        <ToggleButton
                                                            size={'small'}
                                                            value={true}
                                                            children={'Yes'}
                                                        />
                                                    </ToggleButtonGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </Paper>

                        {record.id && <TitleCompanyBlock record={record}/>}

                        {record.id && (
                            <Paper>
                                <div className={'columns__1'}>
                                    <div>
                                        <h3 className={'text__bold'}>Process Status</h3>
                                        <p className={'text__muted'}>
                                            This section can be used to manage funding for the inspection.
                                        </p>
                                    </div>
                                    <ToggleButtonGroup
                                        exclusive
                                        className={'process__toggle'}
                                        value={record.funding_status || 'Not Started'}
                                        onChange={(event, value) => doProcessSave(value)}
                                    >
                                    {processStatuses.map((value, index) => (
                                        <ToggleButton
                                            size={'small'}
                                            value={value}
                                            children={value}
                                        />
                                    ))}
                                    </ToggleButtonGroup>
                                </div>
                            </Paper>
                        )}

                        {record.id && (
                            <Paper>
                                <div className={'columns__1'}>
                                    <div>
                                        <h3 className={'text__bold'}>Collections Status</h3>
                                        <p className={'text__muted'}>
                                            This section can be used to manage the collections process for this inspection.
                                        </p>
                                    </div>
                                    <ToggleButtonGroup
                                        exclusive
                                        className={'process__toggle'}
                                        value={record.collections_status || ''}
                                        onChange={(event, value) => doCollectionsSave(value)}
                                    >
                                        <ToggleButton
                                            size={'small'}
                                            value="CC Funded"
                                            children="CC Funded"
                                        />
                                        <ToggleButton
                                            size={'small'}
                                            value="Check Funded"
                                            children="Check Funded"
                                        />
                                    </ToggleButtonGroup>
                                </div>
                            </Paper>
                        )}
                    </div>

                    <div className={'columns__1'}>
                        <NotesBlock record={record} notes={notes} setNotes={setNotes} />
                        <AgreementBlock record={record}/>
                    </div>
                </div>
            </TabContainer>

            <TabContainer value={tab} target={'inspection'}>
                <div className={'columns__1'}>
                    <IntegrationTab record={record}/>
                </div>
            </TabContainer>

            <TabContainer value={tab} target={'status'}>
                <div className={'columns__1'}>
                    <StatusLogTab record={record}/>
                </div>
            </TabContainer>

            <TabContainer value={tab} target={'payments'}>
                <div className={'columns__1'}>
                    <PaymentsTab record={record}/>
                </div>
            </TabContainer>

            <TabContainer value={tab} target={'funding'}>
                <div className={'columns__1'}>
                    <FundingBlock record={record}/>
                </div>
            </TabContainer>
        </div>
    );
}

export default Transaction;

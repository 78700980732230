import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import DownloadCsv from "../../Generic/DownloadCsv";

import Formatter from "../../Style/Formatter";
import StatusChip from "../../Components/StatusChip";
import StatusLogIndicator from "../../Components/StatusLogIndicator";
import TransactionActions from "../../Components/TransactionActions";

import {Link} from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MUIDataTable from "mui-datatables";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import TransactionProcessStatusAction from "../../Components/TransactionProcessStatusAction";
import TransactionTitleConfirmedAction from "../../Components/TransactionTitleConfirmedAction";
import { Button, Checkbox } from "@mui/material";

/**
 * TransactionsTable component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function TransactionsTable(props) {
    const {loading, setLoading, search, searches, filter, apiPrefixUrl, apiDownloadUrl, processStatuses} = props;

    const [records, setRecords] = useState([]);
    const [recordsSelected, setRecordsSelected] = useState([]);
    const [recordsObj, setRecordsObj] = useState([]);
    const [isDownloading, setDownloading] = useState(false);
    const [recordsLength, setRecordsLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [currentSortOrder, setSortOrder] = useState({name: null, direction: null});

    const table = document.querySelector('table');
    if (document.getElementById("edit_multiple_transactions")) document.getElementById("edit_multiple_transactions").style.display = "none";

    /**
     * Organizes parameters to send to the API
     *
     * @returns {Object}
     */
    const getParametersForUrl = (page = 0, rows = 100, sortOrder = {name: null, direction: null}, download = false) => {
        setCurrentPage(page);
        setRowsPerPage(rows);
        setSortOrder(sortOrder);

        const encodedSearch = search == '' ? null : encodeURIComponent(search);
        const start = page * rows;
        // we need to clone the filter and search objects so we can
        // manipulate the data before sending to the backend
        const filters = Object.assign({}, filter);
        const searchParameters = Object.assign({}, search);

        filters.company = filters?.company ? filters.company.label : '';

        const newSearch = Object.keys(searchParameters).filter((searchTerm) => {
            return search[searchTerm] !== '';
        });

        return {
            start: start,
            offset: rows,
            sortOrder: sortOrder,
            search: newSearch.length > 0 ? { term: newSearch[0], value: search[newSearch[0]]} : null,
            download: download,
            filters: filters,
        };
    };


    /**
     * Loads all transaction results.
     *
     * @returns {Promise<void>}
     */
    const getResults = async (page, rows, sortOrder) => {
        setLoading(true);

        const parameters = getParametersForUrl(page, rows, sortOrder);
        const response = await API.send(apiPrefixUrl + "/search/" + JSON.stringify(parameters));

        if (response?.records?.length > 0) {
            setRecords(response.records);
            setRecordsLength(response.count);
        } else {
            setRecords([]);
            setRecordsLength(0);
        }

        setLoading(false);
    };


    /**
     * Loads the transactions in a downloadable format
     *
     * @returns {String}
     */
    const getDownloadedResults = async () => {
        setDownloading(true);

        const parameters = getParametersForUrl(currentPage, rowsPerPage, currentSortOrder, true);
        const response = await API.send(apiPrefixUrl + "/search/" + JSON.stringify(parameters));

        setDownloading(false);

        return response.records;
    };


    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        getResults().then(() => true);
    }, [filter, searches]);

    /**
     *
     */
    const handleRowClick = (id, checked) => {
        if (checked){
            recordsSelected.push(id);
            recordsObj.push({"id": records[id].id, "status": records[id].status});
        }else{
            const index = recordsSelected.indexOf(id);
            if (index > -1) {
                recordsSelected.splice(index, 1);
                recordsObj.splice(index, 1);
            }
        }
        localStorage.setItem('multiple_transactions', JSON.stringify(recordsObj));
        if (recordsSelected.length > 1){ // only if 2 or more are selected
            document.getElementById("edit_multiple_transactions").style.display = "inline";
        }else{
            document.getElementById("edit_multiple_transactions").style.display = "none";
        }
    };

    return (
        <TableContainer component={'div'} className={'transactions__list'}>
            {loading && <LinearProgress />}
            <MUIDataTable
                className={'table__grid'}
                data={records.map(record => {
                    return {
                        transaction: `${record.order_full_address}, ${record.order_number}, ${record.companykey}`,
                        client: record.client_name,
                        order_amount: record.amount,
                        inspector_amount: record.amount - (record.inspector_fee !== '' ? record.inspector_fee : 50),
                        process_status: record.funding_status || 'Not Started',
                        created_date: record.created_dte,
                        in_promotion: record.in_promotion,
                        inspection_date: record.order_datetime,
                        closing_date: record.closing_dte,
                        tc_ir: Formatter.boolean(record.title_confirmed),
                        collections_status: record.collections_status,
                        status: record.status,
                        inspector_email: record.inspector_email,
                        actions: '',
                    };
                })}
                options={
                    {
                        selectableRows: "none",
                        responsive: 'standard',
                        count: recordsLength,
                        page: currentPage,
                        rowsPerPage: rowsPerPage,
                        rowsPerPageOptions: [50, 100, 250, 500, 1000],
                        filter: false,
                        search: false,
                        print: false,
                        download: true,
                        viewColumns: true,
                        customToolbar: null,
                        fixedHeader: true,
                        tableBodyHeight: 'calc(100vh - 230px)',
                        serverSide: true,
                        onDownload: (buildHead, buildBody, columns, data) => {
                            // because we're waiting on the data we're gonna do part of the CSV creation ourselves
                            const result = getDownloadedResults().then(
                                (responseData) => {
                                    const csv = `${buildHead(columns)}${buildBody(responseData).replace(/'-\$/g, '-$')}`.trim();
                                    DownloadCsv(csv, 'transactions.csv');
                                }
                            );

                            return false;
                        },
                        onTableChange: (action, tableState) => {
                            if (['changePage', 'changeRowsPerPage', 'sort'].indexOf(action) > -1) {
                                getResults(tableState.page, tableState.rowsPerPage, tableState.sortOrder).then(
                                    () => table.scrollIntoView(true)
                                );
                            }
                        },
                        customRowRender: (data, i) => {
                            const record = records[i];

                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        <Checkbox
                                        onChange={(event) => handleRowClick(i, !!event.target.checked)}
                                        >
                                        </Checkbox>
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/transaction/${record.id}`}>
                                            <StatusLogIndicator status={record.status}/>
                                            {record.order_full_address}
                                        </Link>
                                        <div>
                                            OID: {record.order_number}, CK: {record.companykey}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {record.client_name}
                                    </TableCell>
                                    <TableCell>
                                        {Formatter.currency(record.amount)}
                                    </TableCell>
                                    <TableCell>
                                        {Formatter.currency(record.amount - record.inspector_fee)}
                                    </TableCell>
                                    <TableCell>
                                        <TransactionProcessStatusAction
                                            record={record}
                                            onSuccess={getResults}
                                            processStatuses={processStatuses}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {Formatter.date(record.created_dte)}
                                    </TableCell>
                                    <TableCell>
                                        {record.in_promotion}
                                    </TableCell>
                                    <TableCell>
                                        {Formatter.date(record.order_datetime)}
                                    </TableCell>
                                    <TableCell>
                                        {Formatter.date(record.closing_dte)}
                                    </TableCell>
                                    <TableCell>
                                        <TransactionTitleConfirmedAction
                                            record={record}
                                            onSuccess={getResults}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {record.collections_status}
                                    </TableCell>
                                    <TableCell>
                                        <StatusChip record={record}/>
                                    </TableCell>
                                    <TableCell>
                                        <TransactionActions record={record} onReload={getResults}/>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    }
                }
                columns={[
                    {
                        name: "selected",
                        label: " ",
                        options: {
                            sort: false,
                            download: false,
                        }
                    },{
                        name: "transaction",
                        label: "Transaction",
                        options: {
                            sort: true,
                            display: true,
                            download: true,
                        }
                    }, {
                        name: "order_number",
                        label: "Order Number",
                        options: {
                            sort: true,
                            display: false,
                            download: true,
                        }
                    }, {
                        name: "company_key",
                        label: "Company Key",
                        options: {
                            sort: true,
                            display: false,
                            download: true,
                        }
                    }, {
                        name: "client",
                        label: "Client",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "order_amount",
                        label: "Order Amount",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "inspector_amount",
                        label: "Inspector Amount",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "process_status",
                        label: "Process Status",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "created_date",
                        label: "Created Date",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "in_promotion",
                        label: "Created during Promo",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "created_time",
                        label: "Created Time",
                        options: {
                            sort: false,
                            display: false,
                            download: true,
                        }
                    }, {
                        name: "inspection_date",
                        label: "Inspection Date",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "inspection_time",
                        label: "Inspection Time",
                        options: {
                            sort: false,
                            display: false,
                            download: true,
                        }
                    }, {
                        name: "closing_date",
                        label: "Closing Date",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "closing_time",
                        label: "Closing Time",
                        options: {
                            sort: false,
                            display: false,
                            download: true,
                        }
                    }, {
                        name: "tc_ir",
                        label: "TC / IR",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "collections_status",
                        label: "Collections Status",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "status",
                        label: "Status",
                        options: {
                            sort: true,
                        }
                    }, {
                        name: "actions",
                        label: "Actions",
                        options: {
                            sort: true,
                            download: false,
                        }
                    }
                ]}
            />
        </TableContainer>
    );
}

export default TransactionsTable;